// // ######################################
// // --------------------------------------
// // ########### THEME COLORS #############
// // --------------------------------------
// // XX DO NOT CHANGE NAMING CONVENTIONS XX
// // ######################################
// $black-color: #000000;
// $white-color: #ffffff;
// $primary-color: #231942;
// $secondary-color: #5e548e;
// $primary-light: #e87204;
// $primary-light1: #e87204;
// $primary-transparent: #dc5f5f08;
// $primary-transparent1: #23194208;
// $primary-transparent2: #23194250;
// $navlink-color: #e87204;
// $gradient1: #e87204;
// $gradient2: #111d13;

// // #######################################
// // EXTRA COLORS ##########################
// // #######################################
// $light-blue: rgb(71, 118, 235);
// $theme-blue-dark: #5e548e;
// $scrollbar-bg: #d35400;
// $card-purple-bg: #415d431e;
// $orange: #f9c74f;
// $theme-green: #00bf78;
// $theme-green-transparent: #00bf7808;
// $light-green1: #00bf79b3;
// $transparent-green: #00bf7931;
// $dark-gray: #314259;
// $grey: #f8fbff;
// $off-white: #e6e6e6;
// $theme-blue: #57aaee;
// $pink-color: #ff69b4;
// $red-color: #d41d1d;
// $grey1: #cdced4;
// $light-green: #f2fdfa;
// $dark-gray: #314259;
// $transparent-red: #dc5f5f29;
// $divider-color: #9e9c9c;
// $weDo-bg: #f2f2f2;
// $pin-bg: #dcdaff;
// $light-gray: rgb(202, 202, 202);
// $icon-blue: #0465e4;

// // 231942
// //  5e548e
// //#e87204
// // be95c4
// // e0b1cb

// ######################################
// --------------------------------------
// ########### THEME COLORS #############
// --------------------------------------
// XX DO NOT CHANGE NAMING CONVENTIONS XX
// ######################################
$black-color: #000000;
$white-color: #ffffff;
$primary-color: #e87204; // primary color
$primary-light: #ffee32; // primary LIGHT
$primary-color1: #e87204;
$navlink-color: #e87204;
$secondary-color: #4e5555; // secondary color
$primary-color-transparent: #e8720459;

// #######################################
// EXTRA COLORS ##########################
// #######################################
$lime-light: #90a955;
$lime-light1: #90a955;
$transparent-lime: #415d4350;
$transparent-lime1: #415d4308;
$gradient1: #e87204;
$gradient2: #111d13;
$light-blue: rgb(71, 118, 235);
$scrollbar-bg: #d35400;
$card-purple-bg: #415d431e;
$theme-green: #50c085;
$theme-green-transparent: #00bf7808;
$light-green1: #00bf79b3;
$transparent-green: #06b47431;
$dark-gray: #314259;
$grey: #f8fbff;
$off-white: #e6e6e6;
$theme-blue: #57aaee;
$pink-color: #ff69b4;
$red-color: #d41d1d;
$grey1: #cdced4;
$light-green: #f2fdfa;
$dark-gray: #314259;
$transparent-red: #dc5f5f29;
$transparent-lime: #415d4350;
$divider-color: #9e9c9c;
$weDo-bg: #f2f2f2;
$pin-bg: #dcdaff;
$light-gray: rgb(202, 202, 202);
$icon-blue: #0465e4;
